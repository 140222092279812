<template>
  <!-- 加入我们页面 -->
  <div class="join-page bg-page">
    <!-- <div class="banner">
      <img src="@/assets/join/banner.png" alt="">
      <div class="banner-text banner-type-2">
        <p class="zh"><span>岗位清单</span></p>
        <p class="en"><span>POSITION</span></p>
      </div>
    </div> -->
    <img src="@/assets/join/bg.jpg">
    <img class="ani-m" style="top: 31%" src="/img/ani.apng">
    <img class="ani-m" style="top: 61%" src="/img/ani.apng">
    <div class="w">
      <!-- 岗位列表 -->
      <transition-group name="list" tag="ul" class="list-part">
        <li class="list-item" v-for="(item, index) in listData" :key="index" @click.stop="goDetail(item.id)">
          <img :src="imgurl(item.imageUrl)">
          <p class="mb-20 fw-bold">{{ item.title }}</p>
        </li>
      </transition-group>
      <!-- 分页器 -->
      <!-- <div class="pagination-wrap">
        <a-pagination 
          v-model="pageObj.page" 
          :defaultPageSize="pageObj.size" 
          :total="pageObj.total" 
          show-less-items 
          @change="handlePageChange"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { Pagination } from 'ant-design-vue'
import api from '@/api'
const { VUE_APP_API } = process.env 
export default {
  components: { 'a-pagination': Pagination },
  data() {
    return {
      channelId: 40, // 栏目id，用来获取栏目下的内容

      pageObj: {
        page: 1,
        size: 8,
        total: 16
      },
      allData: [], // 全部数据
      listData: []  // 当前页数据
    }
  },  
  async created() {
  //  this.getListData()
    this.getPageData()
  },
  methods: {
    imgurl(path) {
      return `${VUE_APP_API}${path}`.replace('/api', '')
    },
    // 调接口 - 获取指定页的数据
    async getPageData() {
      const { message, contents } = await api.getContentsList({ channelId:this.channelId })
      this.allData = contents.filter(item => item.state === '已审核')  // 存储全部已审核数据
      let jdata = {}
      this.allData.forEach(ele => {
        jdata[ele.id] = ele
      })
      localStorage.setItem('jdata', JSON.stringify(jdata))
      this.pageObj.total = this.allData.length 
      this.getListData() // 截取并展示指定页数据
    },  
    getListData() {
      this.listData = []
      this.$nextTick(() => {
        this.listData = this.allData.slice((this.pageObj.page-1)*this.pageObj.size, this.pageObj.size*this.pageObj.page) // 本地做分页
      })
    },
    // 切换页码
    handlePageChange(page) {
      this.pageObj.page = page
      this.getListData()
    },
    // 去详情页面
    goDetail(id){
      // window.open(window.location.href.split('#/')[0]+`#/joinDetail?channelId=${this.channelId}&contentId=${id}`)
      this.$router.push({
        path: `/joinDetail`,
        query: {
          channelId: this.channelId,
          contentId: id
        }
      })
      scrollToTop()
    }
  }
} 
</script>

<style lang="less">
@import url('~@/styles/m.less');
@import url('~@/styles/animate.less');
.join-page{
  min-height: 100vh;
  // height: 2900px;
  // width: 100vw;
  // background: url('~@/assets/join/bg.jpg') no-repeat;
  // background-size: 100vw;
  position: relative;
  img {
    width: 100%;
  }
  .ani-m {
    position: absolute;
    width: 4vw;
    left: 48vw
  }
  .w{
    padding: 3%;
    position: absolute;
    top: 38%;
    width: 60%;
    left: 20%;
  }
}
/* 岗位清单banner文字 */ 
.banner-type-2{
  position: absolute;
  color:#2e303c;
  font-size: 44px;
  left: 22%;
  top: 36%;
  .fade-scale-in(1,1,-10%, 0%, .8s, ease-out, forwards)
}

.list-part{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .list-item{
    width: 13vw;
    height: 13vw;
    background: url('~@/assets/join/itembg.png');
    background-size: 100%;
    // padding: 30px 40px;
    // background-color: #fff;
    // box-shadow: 0 0 3px 1px rgb(223, 221, 221);
    margin-bottom: 32px;
    transition: .5s;
    position: relative;
    cursor: pointer;
    img {
        display: block;
        width: 50%;
        margin: 0 auto;
        margin-top: 10%
    }
    p {
      color: #fff;
      text-align: center;
      font-size: 1vw;
    }
    &:hover{
      background-image: url('~@/assets/join/itembg-act.png');
      // box-shadow: 0 3px 10px 3px rgb(223, 221, 221);
      // transform: translateY(-10px);
    }
    .more{
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      color: #0065b3;;
      .anticon{
        border: 1px solid #0065b3;
        border-radius: 50%;
        padding: 3px;
        margin-left: 15px;
      }
    }
  }
}
.pagination-wrap{
  text-align: right;
  margin: 30px 0;
}
</style>